import RootPage from "containers/RootPage";
import { isStaging } from "core/model/utils/featureFlags";
import RedirectRoot from "dsl/atoms/RedirectRoot";
import lazy from "dsl/atoms/retry";
import LogoutPage from "dsl/ecosystems/LogoutPage";
import AcpAuthPage, { AcpAuthenticate } from "dsl/organisms/AcpAuthentication";
import AcpSealdDebug from "dsl/organisms/AcpSealdDebug";
import { Navigate, Route, Routes } from "react-router-dom";

const CareproviderAcp = lazy(() => import("dsl/ecosystems/CareproviderAcp"));
const CompanyPage = lazy(() => import("dsl/ecosystems/AcpCompanyPage"));
const CreateCareprovider = lazy(
  () => import("dsl/ecosystems/AcpDashboard/CreateCareprovider"),
);
const CreateCareseeker = lazy(
  () => import("dsl/ecosystems/AcpDashboard/CreateCareseeker"),
);
const AcpDashboard = lazy(() => import("dsl/ecosystems/AcpDashboard"));
const Chromatic = lazy(() => import("dsl/organisms/Chromatic"));
const PasswordResetPage = lazy(() => import("dsl/organisms/PasswordResetPage"));
const InsurancePage = lazy(() => import("dsl/organisms/InsurancePage"));
const Playground = lazy(() => import("dsl/organisms/Playground"));
const InternalAccounts = lazy(() => import("dsl/organisms/InternalAccounts"));
const SearchPage = lazy(() => import("dsl/organisms/SearchPage"));
const AccountNavigation = lazy(
  () => import("dsl/organisms/Account/AcpAccountNavigation"),
);
const AccountPage = lazy(() => import("dsl/organisms/Account"));
const AccountEventsPage = lazy(
  () => import("dsl/organisms/Account/AcpAccountEventTimeline"),
);

const ReportPage = lazy(() => import("dsl/organisms/Careseeker/ReportPage"));
const CareseekerNavigation = lazy(
  () => import("dsl/organisms/Careseeker/AcpCareseekerNavigation"),
);
const ProfilePage = lazy(() => import("dsl/organisms/Careseeker"));
const ProvidersPage = lazy(
  () => import("dsl/organisms/Careseeker/ProvidersPage"),
);
const CareseekerEventsPage = lazy(
  () => import("dsl/organisms/EventTimeline/CareseekerAcpTimeline"),
);
const RulesTableCareseeker = lazy(
  () => import("dsl/ecosystems/CareproviderAcp/RulesTableCareseeker"),
);
const AcpOnpremisePage = lazy(() => import("dsl/organisms/AcpOnpremisePage"));
const TranslationsPanel = lazy(() => import("dsl/ecosystems/AcpTranslations"));
const EmailTranslationsPanel = lazy(
  () => import("dsl/ecosystems/AcpEmailTranslations"),
);
const TestEmailTool = lazy(() => import("dsl/ecosystems/AcpTestEmail"));
const AcpHome = lazy(() => import("dsl/ecosystems/AcpHome"));
const DebugPage = lazy(() => import("dsl/ecosystems/AcpDebugPage"));

const ConsultantUpdatePage = lazy(
  () => import("dsl/ecosystems/AcpConsultantPage"),
);
const ConsultantCreatePage = lazy(
  () => import("dsl/ecosystems/AcpConsultantPage/Create"),
);
const PlaygroundPage = lazy(() => import("dsl/ecosystems/AcpPlaygroundPage"));

export const defaultRedirectPath = "/acp/dashboard/home";
export const acpAuthPath = "/acp/auth";

export default function AcpRoutes() {
  return (
    <Routes>
      <Route index element={<Navigate to={defaultRedirectPath} replace />} />
      <Route element={<RootPage appName="acp" />}>
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/acp/*">
          <Route path="chromatic" element={<Chromatic />} />
          <Route path="info" element={<DebugPage />} />
          <Route path="auth" element={<AcpAuthPage />} />
          <Route
            path="dashboard"
            element={
              <AcpAuthenticate>
                <AcpDashboard />
              </AcpAuthenticate>
            }
          >
            <Route path="home" element={<AcpHome />} />
            <Route path="translations" element={<TranslationsPanel />} />
            <Route
              path="emailtranslations"
              element={<EmailTranslationsPanel />}
            />
            <Route path="testemail" element={<TestEmailTool />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="playground" element={<Playground />} />
            <Route path="sealddebug/:page" element={<AcpSealdDebug />} />
            <Route path="staff" element={<InternalAccounts />} />
            <Route path="careprovider">
              <Route
                path=":careproviderId/:page"
                element={<CareproviderAcp />}
              />
              <Route path=":careproviderId" element={<CareproviderAcp />} />
              <Route
                path="careprovider"
                element={<Navigate to="/acp/dashboard" replace />}
              />
            </Route>
            <Route path="company/:companyid" element={<CompanyPage />} />
            <Route
              path="consultant/:consultant_id"
              element={<ConsultantUpdatePage />}
            />
            <Route
              path="create/careprovider"
              element={<CreateCareprovider />}
            />
            <Route path="create/careseeker" element={<CreateCareseeker />} />
            <Route
              path="create/consultant"
              element={<ConsultantCreatePage />}
            />
            <Route path="insurance" element={<InsurancePage />} />
            <Route path="insurance/:insuranceId" element={<InsurancePage />} />
            <Route path="account/:accountId" element={<AccountNavigation />}>
              <Route index element={<AccountPage />} />
              <Route path="profile" element={<AccountPage />} />
              <Route path="timeline" element={<AccountEventsPage />} />
            </Route>
            <Route
              path="careseeker/:careseekerId"
              element={<CareseekerNavigation />}
            >
              <Route index element={<ProfilePage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="report" element={<ReportPage />} />
              <Route path="providers" element={<ProvidersPage />} />
              <Route path="timeline" element={<CareseekerEventsPage />} />
              <Route
                path="sender-receiver-rules"
                element={<RulesTableCareseeker />}
              />
              <Route path="onpremise" element={<AcpOnpremisePage />} />
            </Route>
          </Route>
          <Route
            path="reset/:token"
            element={<PasswordResetPage authRoute={acpAuthPath} />}
          />
          {/* Super secret, hidden, undocumented "playground" route */}
          {isStaging && (
            <Route path="playground" element={<PlaygroundPage />} />
          )}
          <Route path="*" element={<RedirectRoot to={defaultRedirectPath} />} />
        </Route>
      </Route>
    </Routes>
  );
}
